$(function () {
    $(document).on("change", "input[required]", function (e) {
        try {
            var a = $(this).val();
            $.trim(a);
            $(this).val($.trim(a));
        } catch (error) {}
    });
    $.each($(".alertaNotificacion"), function (i, l) {
        $(l).closest(".treeview").find("a:first").addClass("text-red");
    });
});

$(document).on("hidden.bs.modal", function (event) {
    if ($(".modal:visible").length) {
        $("body").addClass("modal-open");
    }
});

function reload() {
    location.reload(true);
    location.href = _rutaRaiz;
}

function Check() {
    if (typeof $.fn.iCheck === "function") {
        $("input").iCheck({
            checkboxClass: "icheckbox_square-blue",
            radioClass: "iradio_square-blue",
            increaseArea: "20%",
        });
    } else {
        console.warn("iCheck no está disponible. El plugin no pudo ser inicializado.");
    }
}

$(function () {
    function Combo() {
        if ($(".select2").length > 0) {
            $(".select2").select2({
                placeholder: "Seleccione una opción",
                language: {
                    noResults: () => "No se encontraron resultados...",
                    searching: () => "Buscando...",
                },
            });
        }

        if ($(".SO_Multiple").length > 0) {
            $(".SO_Multiple").select2({
                placeholder: "Seleccione una opción",
                closeOnSelect: false,
                language: {
                    noResults: () => "No se encontraron resultados...",
                    searching: () => "Buscando...",
                },
            });
        }
    }

    Combo();

    function Date() {
        if ($("#datemask").length > 0) {
            $("#datemask").inputmask("yyyy/mm/dd", { placeholder: "yyyy/mm/dd" });
        }

        if ($("[data-mask]").length > 0) {
            $("[data-mask]").inputmask();
        }
    }

    Date();

    if (typeof Check === "function") {
        Check();
    }
});


$(document).ajaxComplete(function (event, xhr, statusText) {
    var http = "401";
    var state = xhr.status == http ? true : false;
    if (state) {
        reload();
    }
});

$(document).on("shown.bs.tab", "a[data-toggle=tab]", function (e) {
    $.fn.dataTable.tables({ visible: true, api: true }).columns.adjust();
});

function AplicarTabla(id, filename, order, ordenar) {
    if (!ordenar) {
        ordenar = "asc";
    }
    if (!filename) {
        filename = document.title;
    }
    if (!order) {
        order = 0;
    }
    $(id).DataTable({
        order: [[order, ordenar]],
        destroy: true,
        dom: "flBrtip",
        info: true,
        responsive: true,
        language: {
            decimal: ",",
            info: "Mostrando _START_ a _END_ de _TOTAL_ Entradas",
            infoEmpty: "Mostrando 0 a 0 de 0 Entradas",
            infoFiltered: "(Filtrado de _MAX_ total entradas)",
            infoPostFix: "",
            thousands: ",",
            search: "Buscar:",
            lengthMenu: "Ver_MENU_registros",
            zeroRecords: "No se encontraron registros",
            paginate: {
                first: "Primero",
                previous: "<<",
                next: ">>",
                last: "Último",
            },
        },
        buttons: [
            {
                extend: "excelHtml5",
                className: "btn-sm",
                text: "Exportar a Excel",
                header: true,
                filename: filename,
                exportOptions: {
                    format: {
                        body: function (data, row, column, node) {
                            data = data.replace(/<.*?>/g, "");
                            data = data.replaceAll(".", "");
                            data = data.replaceAll("<br>", "");
                            data = data.replaceAll("</b>", "");
                            data = data.replaceAll("<b>", "");
                            data = data.replaceAll("&nbsp;", " ");
                            return data;
                        },
                    },
                    columns: ":not(.no-exportar)",
                },
            },
        ],
    });
    $(id).css({
        width: "100%",
    });
    $.fn.dataTable
        .tables({
            visible: true,
            api: true,
        })
        .columns.adjust();
}

function AplicarTablaSinOrdernar(id, filename) {
    if (!filename) {
        filename = document.title;
    }
    $(id).DataTable({
        order: [],
        dom: "flBrtip",
        info: true,
        responsive: true,
        pageLength: 25,
        destroy: true,
        language: {
            decimal: ",",
            info: "Mostrando _START_ a _END_ de _TOTAL_ Entradas",
            infoEmpty: "Mostrando 0 a 0 de 0 Entradas",
            infoFiltered: "(Filtrado de _MAX_ total entradas)",
            infoPostFix: "",
            thousands: ",",
            search: "Buscar:",
            lengthMenu: "Ver_MENU_registros",
            zeroRecords: "No se encontraron registros",
            paginate: {
                first: "Primero",
                previous: "<<",
                next: ">>",
                last: "Último",
            },
        },
        buttons: [
            {
                extend: "excelHtml5",
                className: "btn-sm",
                text: "Exportar a Excel",
                header: true,
                filename: filename,
                exportOptions: {
                    format: {
                        body: function (data, row, column, node) {
                            data = data.replace(/<.*?>/g, "");
                            data = data.replaceAll(".", "");
                            data = data.replaceAll("<br>", "");
                            data = data.replaceAll("</b>", "");
                            data = data.replaceAll("<b>", "");
                            data = data.replaceAll("&nbsp;", " ");
                            return data;
                        },
                    },
                    columns: ":not(.no-exportar)",
                },
            },
        ],
    });
    $(id).css({
        width: "100%",
    });
    $.fn.dataTable
        .tables({
            visible: true,
            api: true,
        })
        .columns.adjust();
}