window.onload = function () {
    var loaderEntidad = document.getElementById('loader-entidad');
    if (loaderEntidad) {
        loaderEntidad.style.display = 'none'; 
    }
    document.body.classList.remove('hidden');
};

$(window).on('load', function() {
    if (typeof AplicarDataTable === "function") {
        AplicarDataTable();
    } else {
        console.error('La función AplicarDataTable no está definida.');
    }
});


$(function() {
    var currentPath = window.location.href;
    $('[href="'+currentPath+'"]').addClass('current-menu');
    $('[href="'+currentPath+'"]').parents(".treeview").addClass('active');
    $('[href="'+currentPath+'"]').parents(".treeview-menu").show();
  });

/**
 * 
 * @param {string} elementoClass Clase para aplicar el contraste
 * @returns 
 */
function checkContrast(elementoClass) {
    var array = $(".card-title");
    var arrayTil = $(".card-title a");
    if (typeof elementoClass !== "undefined") {
        array = $(elementoClass);
    }
    array.each((i, e) => {
        var f = $(e).css("background-color");
        var n = $(e).css("color");
        if ($(arrayTil[i]).css("color") != undefined) {
            n = $(arrayTil[i]).css("color");
        }
        var h = "4.5";
        var j = getContrastRatio(n, f);
        j = precisionRound(j, 1);
        if (j < h) {
            var color = "rgb(255,255,255)";
            if (n == "rgb(255, 255, 255)") {
                color = "rgb(0,0,0)";
            } else if (n == "rgb(0, 0, 0)") {
                color = "rgb(255,255,255)";
            }
            if ($(arrayTil[i]).css("color") != undefined) {
                $(arrayTil[i]).css("color", color);
            } else {
                $(e).css("color", color);
            }
        } else {
            var color = "rgb(0,0,0)";
        }
    });
    return true
}
function getContrastRatio(d, c) {
    var a = getLuminosity(d);
    var b = getLuminosity(c);
    if (a > b) {
        return computeContrast(a, b)
    } else {
        return computeContrast(b, a)
    }
}
function getLuminosity(b) {
    var count = (b.match(/,/g) || []).length;
    if (count == 3) {
        b = "rgb(255, 255, 255)";
    }
    var e = /(.*?)rgb\(([ \d]+),([ \d]+),([ \d]+)\)/.exec(b);
    var f = parseInt(e[2]);
    var d = parseInt(e[3]);
    var a = parseInt(e[4]);
    var c = getComposantValue(f) * 0.2126 + getComposantValue(d) * 0.7152 + getComposantValue(a) * 0.0722;
    return c
}
function getComposantValue(a) {
    var b = a / 255;
    if (b <= 0.03928) {
        return b / 12.92
    } else {
        return Math.pow(((b + 0.055) / 1.055), 2.4)
    }
}
function computeContrast(a, b) {
    var resulta = ((a + 0.05) / (b + 0.05))
    return resulta;
}
function precisionRound(c, a) {
    var b = Math.pow(10, a);
    var sol = Math.round(c * b); 
    var division = sol / b;
    return division;
}

function initObserver() {
    const observer = new MutationObserver(() => {
        checkAllClasses();
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true
    });
}

function checkAllClasses() {
    const classes = [
        ".card-title",
        ".form-control",
        ".sidebar-toggle",
        ".colorcontraste",
        ".dropdown-menu",
        ".btn",
        ".dow",
        ".input-helper",
        ".buttons-excel",
        ".btn-primary",
        ".table thead th"
    ];

    classes.forEach(cls => checkContrast(cls));

    setTimeout(() => {
        checkContrast(".btn");
        checkContrast(".btn-primary");
        checkContrast(".colorcontraste");
        checkContrast(".table thead th");
        checkContrast(".buttons-excel");
    }, 500);
}

document.addEventListener("DOMContentLoaded", () => {
    checkAllClasses(); 
    initObserver();
});

  //Finalización de personalizar plataforma

  function lanzarConfeti() {
    var confettiDiv = document.createElement('div');
        confettiDiv.id = 'confetti-container';
        document.body.appendChild(confettiDiv);

    const confettiContainer = document.getElementById('confetti-container');
  
    for (let i = 0; i < 100; i++) {
      const confetti = document.createElement('div');
      confetti.classList.add('confetti');
      
      confetti.style.backgroundColor = getRandomColor();
      confetti.style.left = `${Math.random() * 100}vw`;
      confetti.style.animationDelay = `${Math.random() * 2}s`; 
  
      confettiContainer.appendChild(confetti);
    }
  }
  
  function getRandomColor() {
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A8', '#F9FF33'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

/**
 * Convierte el objeto jQuery en un elemento DOM si es necesario.
 * @param {*} el - El objeto que puede ser un elemento jQuery o un elemento DOM.
 * @returns {HTMLElement} - El elemento DOM.
 */

function convertDomjQueryElement(el) {
    return el instanceof jQuery ? el[0] : el;
}

/**
 * @param {clase o id} selector Individual para remover d-flex y añadir d-none
 */

function ocultarDisplayFlex(selector) {
    $(selector).removeClass('d-flex').addClass('d-none');
}

/**
 * @param {clase o id} selector Individual selector para remover d-none y añadir d-flex
 */

function mostrarDisplayFlex(selector) {
    $(selector).removeClass('d-none').addClass('d-flex');
}

/**
 * @param {clase o id} selector Individual selector para remover d-none y añadir d-block como el .show() de jQuery
 */

function mostrarDisplayBlock(selector) {
    $(selector).removeClass('d-none').addClass('d-block');
}

/**
 * Muestra los elementos con 'd-flex' y oculta 'd-none'.
 * @param {HTMLElement[]} elementos - Array de elementos DOM.
 */
function arrayVerDisplayFlex(elementos) {
    elementos.forEach(function(el) {
        el = convertDomjQueryElement(el);
        el.classList.remove('d-none');
        el.classList.add('d-flex');
    });
}

/**
 * Oculta los elementos con 'd-none' y elimina 'd-flex'.
 * @param {HTMLElement[]} elementos - Array de elementos DOM.
 */
function arrayOcultarElementos(elementos) {
    elementos.forEach(function(el) {
        el = convertDomjQueryElement(el);
        el.classList.remove('d-flex');
        el.classList.add('d-none');
    });
}

